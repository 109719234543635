
.App {
    display: flex;
    flex-flow: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.Filters {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    padding: 1em;
    flex: 0 0 auto;
}

.Content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 1em;
    /* box-shadow: 2px 1px 1px grey; */
}

.FilterElement {
    width: 10%;
    margin-right: 1em;
}

.ConjugationTable {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    overflow: clip;
}

.TableContainerBox {
    overflow: auto;
}

.TableCell {
    width: 0;
    white-space: nowrap;
}

.TableCellLast {
    width: auto;
}

.GapContainer {
    white-space: nowrap;
    margin: 0;
    padding: 0;
}

.Tooltip {
    transform: scale(0.6);
    position: relative;
    right: 22px;
    top: 0px;
    color: gray;
}

.Gap {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    border-radius: 2px;
    border-left: 0;
    border-right: 0;
    border-bottom-width: 2px;
    border-top-width: 2px;
    border-top-color: #ffffff00;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 18px;
    outline: none;
    width: 200px;
    display: inline-block;
}

.Gap-Passif {
    width: 300px;
}

.passif-list {
    margin: 1em;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    justify-content: flex-start;
}

